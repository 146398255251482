<template>
  <div>
    <el-page-header
      icon="el-icon-arrow-left"
      content="用户详情"
      @back="goBack"
    ></el-page-header>
    <div v-loading="loading" class="table-content">
      <div class="user-content">
        <div class="avatar">
          <el-avatar :src="studentData.picture" :size="120">
            <img
              src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
            />
          </el-avatar>
          <div>{{ studentData.username }}</div>
        </div>
        <div class="info-content">
          <el-descriptions title="用户信息" :column="2">
            <el-descriptions-item label="账号：">{{
              studentData.phone
            }}</el-descriptions-item>
            <el-descriptions-item label="地区："
              >{{ studentData.province
              }}{{ studentData.city }}</el-descriptions-item
            >
            <el-descriptions-item label="性别：">{{
              studentData.sex
            }}</el-descriptions-item>
            <el-descriptions-item label="年龄：">{{
              studentData.birth
            }}</el-descriptions-item>
            <el-descriptions-item label="标签："
              ><el-tag
                size="medium"
                v-for="(item, index) in studentData.tags"
                :key="index"
                closable
                effect="dark"
                style="margin-right: 20px"
                @close="handDelete(index)"
                >{{ item.title }}</el-tag
              >
              <el-button type="primary" size="mini" @click="handEditor"
                >修改</el-button
              >
            </el-descriptions-item>
            <el-descriptions-item label="机构：">
              <el-input
                v-model="input"
                :maxlength="10"
                size="small"
                style="width: 150px"
              ></el-input>
              <el-button type="primary" size="mini" @click="handOname"
                >修改</el-button
              >
            </el-descriptions-item>
            <el-descriptions-item label="注册时间：">
              {{ studentData.createdtime }}
            </el-descriptions-item>
            <el-descriptions-item label="最近登录时间：">{{
              studentData.modifiedtime
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="纠音APP使用情况" name="first">
          <div class="tab-num">
            <span>检索次数：{{ numData.search_num }}次，</span>
            <span>最后一次检索时间：{{ numData.search_time }}</span>
          </div>
          <div class="tab-num">
            <span>跟读次数：{{ numData.follow_num }}次，</span>
            <span>最后一次跟读时间：{{ numData.follow_time }}</span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="修改标签" v-model="dialogVisible" width="400px">
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          class="check-item"
          v-for="(item, index) in tagList"
          :key="index"
          :label="item.id"
          @change="handCheck(index)"
          >{{ item.title }}</el-checkbox
        >
      </el-checkbox-group>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handSubmit">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  studentDetail,
  studentDelstutag,
  studentStutaglist,
  studentSavestutag,
  studentEditOrg,
  studentUsage,
} from "@/api/apiList/user-api";
export default {
  data() {
    return {
      studentData: {},
      input: "",
      loading: true,
      dialogVisible: false,
      tagList: [],
      checkList: [],
      titleList: [],
      activeName: "first",
      numData: {},
    };
  },
  methods: {
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    // 删除标签
    handDelete(e) {
      this.$confirm("是否删除此标签?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: this.$route.params.id,
            tag_id: this.studentData.tags[e].id,
            tag_name: this.studentData.tags[e].title,
          };
          studentDelstutag(obj).then((res) => {
            if (res.result === "200") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.handDetail();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改标签
    handEditor() {
      let obj = {
        id: this.$route.params.id,
      };
      studentStutaglist(obj).then((res) => {
        if (res.result === "200") {
          this.tagList = res.data;
          this.checkList = res.data
            .filter((item) => item.is_check === 1)
            .map((item) => item.id);
          this.titleList = res.data
            .filter((item) => item.is_check === 1)
            .map((item) => item.title);
          this.dialogVisible = true;
        }
      });
    },
    // 选择标签
    handCheck(index) {
      this.tagList[index].is_check = this.tagList[index].is_check === 1 ? 2 : 1;
      this.titleList = this.tagList
        .filter((item) => item.is_check === 1)
        .map((item) => item.title);
    },
    // 保存标签
    handSubmit() {
      let obj = {
        id: this.$route.params.id,
        tag_ids: this.checkList,
        tag_names: this.titleList,
      };
      studentSavestutag(obj).then((res) => {
        if (res.result === 200) {
          this.handDetail();
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      });
    },
    // 修改机构
    handOname() {
      let obj = {
        id: this.$route.params.id,
        origanization_name: this.input,
      };
      studentEditOrg(obj).then((res) => {
        if (res.result === 200) {
          this.handDetail();
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      });
    },
    // 详情
    handDetail() {
      let obj = {
        id: this.$route.params.id,
      };
      studentDetail(obj).then((res) => {
        if (res.result === "200") {
          this.studentData = res.data;
          this.input = res.data.origanization_name;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.handDetail();
    let obj = {
      student_id: this.$route.params.id,
    };
    studentUsage(obj).then((res) => {
      if (res.result === "200") {
        this.numData = res.data;
      }
    });
  },
};
</script>

<style lang="scss">
.user-content {
  display: flex;
  align-items: center;
  margin: 30px 0;
  padding: 20px 40px;
  border-radius: 8px;
  background: #eee;

  .avatar {
    margin-right: 40px;
    text-align: center;
  }

  .info-content {
    flex: 1;

    .el-descriptions__body {
      background: #eee;
    }
  }
}

.tab-num {
  padding: 0 20px;
  line-height: 3;
}

.check-item {
  display: block;
  margin-bottom: 10px;
}
</style>
