import { get, post } from "../request";

// 地区列表
export const addressList = (params) => get("/address/list", params);

// 用户列表
export const studentList = (params) => get("/student/list", params);

// 用户详情
export const studentDetail = (params) => get("/student/detail", params);

// 删除标签
export const studentDelstutag = (params) => post("/student/delstutag", params);

// 用户标签列表
export const studentStutaglist = (params) => get("/student/stutaglist", params);

// 保存用户标签
export const studentSavestutag = (params) =>
  post("/student/savestutag", params);

// 修改机构
export const studentEditOrg = (params) => post("/student/editOrg", params);

// App使用情况
export const studentUsage = (params) => get("/student/usage", params);

// 机构列表
export const organizationList = (params) => get("/organization/list", params);

// 新增/编辑机构
export const organizationEdit = (params) => post("/organization/edit", params);

// 机构详情
export const organizationDetail = (params) =>
  get("/organization/detail", params);

// 启用结构
export const organizationStart = (params) => get("/organization/start", params);

// 禁用机构
export const organizationStop = (params) => get("/organization/stop", params);

// 标签列表
export const tagList = (params) => get("/tag/list", params);

// 添加/编辑标签
export const tagEdit = (params) => post("/tag/edit", params);

// 标签详情
export const tagDetail = (params) => get("/tag/detail", params);

// 启用标签
export const tagStart = (params) => get("/tag/start", params);

// 禁用标签
export const tagStop = (params) => get("/tag/stop", params);
